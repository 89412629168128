import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=21429418&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=21429418&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21429418",
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.limburg/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BButton, BButtonGroup, BFormSelect, BDropdownItem, BDropdown, BRow} from 'bootstrap-vue'
    installComponents(component, {BCol, BButton, BButtonGroup, BFormSelect, BDropdownItem, BDropdown, BRow})
    

export default component.exports